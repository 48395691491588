import React from 'react';
import { Helmet } from "react-helmet";

export default function Meta({ title, description, link, image }) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:url" content={link} />
            <meta property="og:image" itemprop="image primaryImageOfPage" content={image || "/logo512.png"} />
            <meta name="twitter:title" property="og:title" itemprop="name" content={title} />
            <meta name="twitter:description" property="og:description" itemprop="description" content={description} />
        </Helmet>
    );
}