import React, { useEffect } from 'react';
import './App.css';
import ReactGA from 'react-ga';
import Navbar from './components/Navbar';
import history from './utils/history';
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { userState } from './data/content';
import firebaseauth from './utils/firebaseAuth'
import blue from '@material-ui/core/colors/blue';

ReactGA.initialize('UA-177855872-1');
history.listen(location => {
  ReactGA.pageview(location.pathname + location.search);
});

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));
let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff9933"
    },
    secondary: {
      main: blue[500],
    },
  },
});

theme = responsiveFontSizes(theme);
function App() {
  const [, setUser] = useRecoilState(userState);
  const classes = useStyles();
  useEffect(() => {
    firebaseauth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        setUser({
          displayName: currentUser.displayName,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
          phoneNumber: currentUser.phoneNumber,
          isLoggedIn: true,
          isLoaded: true
        })
      } else {
        setUser({
          isLoggedIn: false,
          isLoaded: true
        });
      }
    }, error => console.log(error));
  }, [setUser])

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.toolbar} />
      <Navbar />
    </ThemeProvider>
  );
}

export default App;
