import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy'
import { useParams } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { videosState } from '../../data/content';
import Facebook from '../../components/Facebook';
import { Comments, Like } from 'react-facebook';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import tagToPath from '../../utils/tagToPath';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
    },
}));

function MediaPlay() {
    const history = useHistory();
    const classes = useStyles();
    const [activeId, setActiveId] = useState();
    let { section, videoId } = useParams();
    const videos = useRecoilValue(videosState);
    const video = videos.find(video => video.hashtags.map(hashtag => tagToPath(hashtag)).includes(section) && video.videoId === videoId);
    const handleTimeUpdate = (e) => {
        const currentTime = e.playedSeconds;
        if (video.subtitle) {
            video.subtitle.forEach(item => {
                if (currentTime > item.start && currentTime < item.end) {
                    setActiveId(item.id);
                }
            });
        }
    }
    const handleClick = (section) => {
        history.push(`/media/${tagToPath(section)}`);
    }
    return (
        <div className="container-fluid">
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">Vakovakyam</Link>
                <Link color="inherit" href="/media">media</Link>
                <Link color="inherit" href={`/media/${section}`}>{section}</Link>
                <Typography color="textPrimary">{video.title}</Typography>
            </Breadcrumbs>
            <div className="row mt-4 justify-content-center">
                <div className="col-lg-6">
                    <ReactPlayer url={video.video} width="100%" controls onProgress={handleTimeUpdate} />
                    {video?.hashtags?.map(item => {
                        return (
                            <Chip key={item} size="small" onClick={() => handleClick(item)} className={classes.chip} label={item} clickable />
                        );
                    })}
                    <Facebook>
                        <Like colorScheme="dark" showFaces share />
                        <Comments />
                    </Facebook>
                </div> {
                    video?.subtitle
                        ? <div className="col-lg-6">
                            {video?.subtitle?.map(item => {
                                return (
                                    <div key={item.id}>
                                        <p className={activeId === item.id ? "subtitle-active" : "subtitle"}>{item.title}</p>
                                        {item.id % 2 === 0 ? <br /> : null}
                                    </div>

                                );
                            })}
                        </div>
                        : null
                }
            </div>
        </div>
    );
}

export default MediaPlay;