import React from 'react';
import { Link } from "react-router-dom";
import Share from '../Share';

function MediaCard({ image, title, link }) {
    return (
        <div className="shadow card mr-4 h-100">
            <div className="embed-responsive embed-responsive-16by9">
                <Link to={link}>
                    <img className="card-img-top embed-responsive-item" src={image} alt={title} />
                </Link>
            </div>
            <div className="card-body">
                <Link to={link}> <p className="card-title">{title}</p></Link>
                {/* <Facebook>
                    <Like href={`https://www.vakovakyam.com/${link}`} />
                </Facebook> */}
                <Share link={`https://www.vakovakyam.com/${link}`} text={title} />
            </div>
        </div>
    );

}

export default MediaCard;