import React from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../data/content';
import firebaseauth from '../utils/firebaseAuth'
import { useHistory } from "react-router-dom";

function Profile() {
    const history = useHistory();
    const [user, setUser] = useRecoilState(userState);
    const handleSignOut = () => {
        firebaseauth.signOut().then(function () {
            setUser({
                isLoggedIn: false,
                isLoaded: true
            });
            history.push('/login');
        }, function (error) {
            console.error('Sign Out Error', error);
        });
    }
    return (
        <div className="container">
            <div className="row m-5 justify-content-center">
                <div className="col-lg-4">
                    <img className="avatar" src={user?.photoURL ? user?.photoURL : "/images/avatar.jpg"} alt="avatar" />
                    <p className="profile-usertitle-name">{user?.displayName}</p>
                    <p className="profile-usertitle-email">{user?.email}</p>
                    <div className="text-center">
                        <button type="button" className="btn btn-danger" onClick={handleSignOut}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;