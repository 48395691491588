import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MediaItem from './media/MediaItem';
import { useRecoilValue } from 'recoil';
import { videosState } from '../data/content';
import tagToPath from '../utils/tagToPath';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Contributor() {
  const { id } = useParams();
  const history = useHistory();
  const videos = useRecoilValue(videosState)
    .filter(video => video.hashtags.map(hashtag => tagToPath(hashtag)).includes(id));
  let tags = [];
  videos.forEach(video => {
    video.hashtags.forEach(hashtag => {
      if (!tags.includes(hashtag)) {
        tags.push(hashtag);
      }
    });
  });
  const classes = useStyles();
  const handleClick = (section) => {
    history.push(`/media/${tagToPath(section)}`);
  };
  return (
    <div className="container">
      <div className="row mt-5 justify-content-center">
        <Avatar alt="Contributor" src="/images/chetana-pandey.jpeg" className={classes.large} />
        <div className="col-lg-6">
          <Typography align="center"
            variant="h4"
            color="primary">
            Dr. Chetana Pandey
        </Typography>
          <Typography align="center"
            variant="body1"
            color="inherit">
            A Hindi scholar, writer and teacher, was born on 10th September, 1971 in Varanasi, Uttar Pradesh. She completed her MA and PhD from Banaras Hindu University (BHU), Varanasi, Uttar Pradesh. Her interests include writing peotry, singing, acting and shloka recitation. A melodious singer, she has performed at the Rashtrapati Bhavan, Vigyan Bhavan and many mega events at national platforms. Her performances have also aired on several radio and television channels.
        </Typography>
        </div>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div style={{ textAlign: "center" }}>
                {tags?.map(item => {
                  return (
                    <Chip key={item} color="primary" clickable={true} onClick={() => handleClick(item)} className={classes.chip} label={item} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-5 justify-content-center">
        <div className="col-lg-12">
          <Typography align="center"
            variant="h4"
            color="inherit">
            Contributions
                </Typography>
          <MediaItem section={id} />
        </div>
      </div>
    </div>
  );
}