import React from 'react';
import { useRecoilValue } from 'recoil';
import { galleryState } from '../data/content';
import { useParams } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import SideBar from './SideBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function Gallery() {
    const classes = useStyles();
    const galleryList = useRecoilValue(galleryState);
    const { galleryId } = useParams();
    const gallery = galleryList.find(gallery => gallery.id === galleryId);
    const sideBarData = galleryList.map(gallery => ({ title: gallery.title, href: `/gallery/${gallery.id}`, selected: gallery.id === galleryId }))
    return (
        <div className={classes.root}>
            <CssBaseline />
            <SideBar data={sideBarData} />
            <main className={classes.content}>
            <div class="jumbotron mt-5">
                <h1 class="display-4">Gallery</h1>
                <p class="lead">चित्रवीथिका/Image gallery is a way to revisit the post or to pratyabhijñā, which tells a thousand words, a complete story, but there is real value in using images to impart knowledge or information based content for our incessant consciousness. Images help us to learn, grab our attention, explain the concepts, and always inspire.</p>
            </div>
            <p className="shlok">{gallery.title}</p>
            <ImageGallery autoPlay="true" thumbnailPosition="right" items={gallery.images} />
            </main>
        </div>
    );
}

export default Gallery;