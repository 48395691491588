import React from 'react';
import { useRecoilValue } from 'recoil';
import { shlokState } from '../data/content';
import Share from './Share';

function Shlok() {
    const shlok = useRecoilValue(shlokState);
    const backgroundStyle = {
        backgroundImage: "url(/images/scroll.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
    }
    return (
        <div className="row p-1 justify-content-center">
            <div className="col-lg-8 p-5" style={backgroundStyle}>
                <p className="shlok display-linebreak">
                    {shlok.sanskrit}
                </p>
                <div className="collapse" id="collapse">
                    <p className="shlok-subtitle display-linebreak">
                        {shlok.transilitration}
                    </p>
                    <p className="shlok-subtitle display-linebreak">
                        {shlok.hindi}
                    </p>
                    <p className="shlok-subtitle display-linebreak">
                        {shlok.english}
                    </p>
                </div>
                <p className="text-center">
                    <a className="collapsed" data-toggle="collapse" href="#collapse">
                        <span className="if-collapsed">See </span>
                        <span className="if-not-collapsed">Hide </span>
                    translation
                    </a>
                </p>
                <Share text={shlok.sanskrit} />
            </div>
        </div>
    );
}

export default Shlok;
