import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function ListItemLink(props) {
    const { selected, primary, to } = props;

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link ref={ref} to={to} {...linkProps} />
            )),
        [to],
    );

    return (
        <ListItem button selected={selected} component={CustomLink} divider={true}>
            <ListItemText primary={primary} />
        </ListItem>
    );
}
