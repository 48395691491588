import React from 'react';
import { useRecoilValue } from 'recoil';
import { aartiState } from '../data/content';
import Typography from '@material-ui/core/Typography';
import Share from './Share';
import { Link } from "react-router-dom";

export default function AartiSlice() {
    const aartiList = useRecoilValue(aartiState);
    const aarti = aartiList[aartiList.length - 1]
    return (
        <>
            <Typography align="center"
                variant="h4"
                color="inherit">
                स्तवनम्
                    </Typography>
            <img className="avatar" src={aarti.image} alt="Aarti" />
            <p className="shlok display-linebreak">
                {aarti.content.split("\n", 5).join("\n")}
            </p>
            <div className="text-center">
                <Share text={aarti.title} link="https://www.vakovakyam.com/aarti/aarti-kunjbihari-ki" />
                <Link to="/aarti/aarti-kunjbihari-ki" type="button" className="btn btn-outline-primary m-3">Show more</Link>
            </div>
        </>
    );
}