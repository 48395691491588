import React, {useEffect} from 'react';
import Media from './MediaItem';
import { useRecoilValue } from 'recoil';
import { sectionsState, topSectionsState } from '../../data/content';
import Latest from './Latest';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import tagToPath from '../../utils/tagToPath';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    chip: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
}));

function MediaPage() {
    const { pathname } = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const sections = useRecoilValue(sectionsState);
    const topSections = useRecoilValue(topSectionsState);
    const handleClick = (section) => {
        history.push(`/media/${tagToPath(section)}`);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return (
        <div className="container">
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">Vakovakyam</Link>
                <Typography color="textPrimary">media</Typography>
            </Breadcrumbs>
            <div className="jumbotron mt-5">
                <h1 className="display-4">Media</h1>
                <p className="lead">This section is an ocean of content in video etc. variants. Through knowledge based videos you can build your followers with a clear understanding of content you are going to post  as well as what your audience wants to see. (This guide is meant to serve as a starting point for your media posts that you can adapt.)</p>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <Chip color="primary" clickable={true} onClick={() => handleClick("Latest")} className={classes.chip} label="Latest" />
                    <Latest />
                </div>
            </div>
            {sections.map(section => {
                return (
                    <div key={section} className="row mb-3">
                        <Chip color="primary" clickable={true} onClick={() => handleClick(section)} className={classes.chip} label={section} />
                        <Media section={tagToPath(section)} />
                    </div>
                );
            })}
            <div className="row m-5 justify-content-center">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Top Tags</h5>
                            <div style={{ textAlign: "center" }}>
                                {topSections?.map(item => {
                                    return (
                                        <Chip key={item} color="primary" clickable={true} onClick={() => handleClick(item)} style={{ color: randDarkColor() }} className={classes.chip} variant="outlined" label={item} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function randDarkColor() {
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}

export default MediaPage;