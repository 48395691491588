import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userState, authenticationState, mobileOpenState, mobileShowState } from '../data/content';
import {
    Router,
    Switch,
    Route,
    Redirect,
    Link
} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Home from './Home';
import Gallery from './Gallery';
import MediaPlay from './media/MediaPlay'
import MediaSection from './media/MediaSection'
import Aarti from './Aarti';
import Login from './Login';
import Profile from './Profile';
import MediaPage from './media/MediaPage';
import history from '../utils/history';
import Parkiya from './Parkiya';
import Contributor from './Contributor';

const About = lazy(() => import('./About'));
const EBook = lazy(() => import('./EBook'));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    link: {
        color: "#ffffff",
        padding: theme.spacing(3),
    },
}));

const Navbar = () => {
    const classes = useStyles();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const isUserAuthenticated = useRecoilValue(authenticationState);
    const [user,] = useRecoilState(userState);
    const [mobileOpen, setMobileOpen] = useRecoilState(mobileOpenState);
    const [mobileShow,] = useRecoilState(mobileShowState);

    return (
        <Router history={history}>
            <div>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        {mobileShow ? <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                            : null}

                        <Typography type="title" color="inherit" style={{ flex: 1 }}>
                            <Link style={{ color: "#000000" }} className="navbar-brand" to="/">Vakovakyam</Link>
                        </Typography>
                        {isUserAuthenticated
                            ? <Link style={{ color: "#000000" }} className="nav-link active" to="/profile">Profile</Link>
                            : <Link style={{ color: "#000000" }} className="nav-link active" to="/login">Login</Link>}
                        <Link style={{ color: "#000000" }} className="nav-link active" to="/about">About</Link>
                    </Toolbar>
                </AppBar>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <PrivateRoute path="/profile">
                            <Profile />
                        </PrivateRoute>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/ebook/:bookId">
                            <EBook />
                        </Route>
                        <Route exact path="/media">
                            <MediaPage />
                        </Route>
                        <Route exact path="/media/:section">
                            <MediaSection />
                        </Route>
                        <Route path="/media/:section/:videoId">
                            <MediaPlay />
                        </Route>
                        <Route path="/gallery/:galleryId">
                            <Gallery />
                        </Route>
                        <Route path="/aarti/:aartiId">
                            <Aarti />
                        </Route>
                        <Route path="/परकीया/:parkiyaId">
                            <Parkiya />
                        </Route>
                        <Route path="/contributor/:id">
                            <Contributor />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </Suspense>
            </div>
        </Router>
    );
    function PrivateRoute({ children, ...rest }) {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    user.isLoaded ? (user.isLoggedIn ? (
                        children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }}
                            />
                        )) : <></>
                }
            />
        );
    }
}


export default Navbar;