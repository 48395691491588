import React from 'react';
import { useRecoilValue } from 'recoil';
import { parkiyaState } from '../data/content';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Share from './Share';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    line: {
        color: "blue",
    }
}));

export default function ParkiyaSlice() {
    const classes = useStyles();
    const parkiyaList = useRecoilValue(parkiyaState);
    const parkiya = parkiyaList[parkiyaList.length - 1]
    return (
        <>
            <Typography align="center"
                variant="h4"
                color="inherit">
                परकीया
                        <div><br /><Divider /></div>
            </Typography>
            <Typography align="center"
                variant="h5"
                color="inherit">
                {parkiya.title}
                <Divider />
            </Typography>

            {parkiya.content.slice(0, 5).map(line => {
                return (
                    <div key={line.text}>
                        <Typography className={classes.line} align={line.align} variant="body1" gutterBottom>
                            {line.text}
                        </Typography>
                        <Divider />
                    </div>
                );
            })}
            <div className="text-center">
                <Share text={parkiya.title} link="https://www.vakovakyam.com/परकीया/maine-to-socha-tha" />
                <Link to="/परकीया/maine-to-socha-tha" type="button" className="btn btn-outline-primary m-3">Show more</Link>
            </div>
        </>
    );
}