import React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

export default function Share({link, text, hashtags }) {
    const shareUrl = link || "https://www.vakovakyam.com";
    const icondSize = 32;
    return (
        <div className="row justify-content-center">
            <div className="m-1">
                <FacebookShareButton
                    url={shareUrl}
                    quote={text}
                >
                    <FacebookIcon size={icondSize} round />
                </FacebookShareButton>
            </div>
            <div className="m-1">
                <TwitterShareButton
                    url={shareUrl}
                    title={text}
                    hashtags={hashtags}
                >
                    <TwitterIcon size={icondSize} round />
                </TwitterShareButton>
            </div>
            <div className="m-1">
                <WhatsappShareButton
                    url={shareUrl}
                    title={text}
                    separator=" :: "
                >
                    <WhatsappIcon size={icondSize} round />
                </WhatsappShareButton>
            </div>
        </div>
    );
}