import React from 'react';
import Shlok from './Shlok';
import MediaItem from './media/MediaItem';
import Typography from '@material-ui/core/Typography';
import ParkiyaSlice from './ParkiyaSlice'
import AartiSlice from './AartiSlice';
import GallerySlice from './GallerySlice';
import { Link } from "react-router-dom";
import ContributorSlice from './ContributorSlice';
import BookSection from './BookSection';

function Home() {
    return (
        <div className="container-fluid">
            <Shlok />
            <div className="row p-3 mt-3 justify-content-center">
                <ContributorSlice />
            </div>
            <div className="row p-3 mt-3" style={{ backgroundColor: "#fff9e4" }}>
                <div className="col-lg-12 justify-content-center">
                    <Typography align="center"
                        variant="h4"
                        color="inherit">
                        Sanskrit Recitations
                    </Typography>
                    <MediaItem section="sanskrit" />
                    <div className="text-center">
                        <Link to="/media" type="button" className="btn btn-outline-primary m-3">Show more</Link>
                    </div>
                </div>
            </div>
            <div className="row p-3 mt-3 justify-content-center">
                <div className="col-lg-4">
                    <ParkiyaSlice />
                </div>
            </div>

            <div className="row p-3 mt-3" style={{ backgroundColor: "#fff9e4" }}>
                <div className="col-lg-12 justify-content-center">
                    <Typography align="center"
                        variant="h4"
                        color="inherit">
                        EBooks
                    </Typography>
                    <BookSection />
                </div>
            </div>
            <div className="row p-3 mt-3 justify-content-center">
                <div className="col-lg-6">
                    <AartiSlice />
                </div>
            </div>
            <div className="row p-3 mt-3" style={{ backgroundColor: "#fff9e4" }}>
                <div className="col-lg-12 justify-content-center">
                    <Typography align="center"
                        variant="h4"
                        color="inherit">
                        Lectures
                    </Typography>
                    <MediaItem section="lecture" />
                    <div className="text-center">
                        <Link to="/media" type="button" className="btn btn-outline-primary m-3">Show more</Link>
                    </div>
                </div>
            </div>
            <div className="row p-3 mt-3">
                <div className="col-lg-12 justify-content-center">
                    <GallerySlice />
                </div>
            </div>
        </div>
    );
}

export default Home;