import {
    atom,
    selector,
} from 'recoil';
import data from './data.json';

const contentState = atom({
    key: 'content',
    default: data,
});

export const userState = atom({
    key: 'userState',
    default: { isLoggedIn: false, isLoaded: false }
});

export const mobileOpenState = atom({
    key: 'mobileOpenState',
    default: false
});

export const mobileShowState = atom({
    key: 'mobileShowState',
    default: false
});

export const authenticationState = selector({
    key: 'authenticationState',
    get: ({ get }) => {
        const user = get(userState);
        return user.isLoggedIn;
    },
});

export const parkiyaState = selector({
    key: 'parkiyaState',
    get: ({ get }) => {
        const content = get(contentState);
        return content.parkiya;
    },
});

export const galleryState = selector({
    key: 'galleryState',
    get: ({ get }) => {
        const content = get(contentState);
        return content.gallery;
    },
});

export const shlokState = selector({
    key: 'shlok',
    get: ({ get }) => {
        const content = get(contentState);
        return content.shlok[Math.floor(Math.random() * data.shlok.length)];
    },
});

export const hanumanChalisaState = selector({
    key: 'hanumanChalisa',
    get: ({ get }) => {
        const content = get(contentState);
        return content.aarti[0].hanumanChalisa;
    },
});

export const aartiState = selector({
    key: 'aarti',
    get: ({ get }) => {
        const content = get(contentState);
        return content.aarti;
    },
});

export const sectionsState = selector({
    key: 'sections',
    get: ({ get }) => {
        const content = get(contentState);
        return content.sections;
    },
});

export const topSectionsState = selector({
    key: 'topSections',
    get: ({ get }) => {
        const content = get(contentState);
        return content.topSections;
    },
});

export const videosState = selector({
    key: 'videos',
    get: ({ get }) => {
        const content = get(contentState);
        return content.videos.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
});

export const booksState = selector({
    key: 'books',
    get: ({ get }) => {
        const content = get(contentState);
        return content.books.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
});

export default contentState;