import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { parkiyaState } from '../data/content';
import { useParams } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import SideBar from './SideBar';
import Share from './Share';
import { useLocation } from "react-router-dom";
import Meta from './Meta';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    line: {
        color: "blue",
    }
}));

function Parkiya() {
    const { pathname } = useLocation();
    const classes = useStyles();
    const parkiyaList = useRecoilValue(parkiyaState);
    const { parkiyaId } = useParams();
    const parkiya = parkiyaList.find(parkiya => parkiya.parkiyaId === parkiyaId);
    const sideBarData = parkiyaList.map(parkiya => ({ title: parkiya.title, href: `/परकीया/${parkiya.parkiyaId}`, selected: parkiya.parkiyaId === parkiyaId }))

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={classes.root}>
            <Meta title={parkiya.title} description={parkiya.content[0].text} link={`https://www.vakovakyam.com/परकीया/${parkiya.parkiyaId}`} />
            <CssBaseline />
            <SideBar data={sideBarData} />
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Vakovakyam</Link>
                    <Typography color="textPrimary">परकीया</Typography>
                </Breadcrumbs>
                <Typography align="center"
                    variant="h5"
                    color="inherit">
                    {parkiya.title}
                    <br />
                    {parkiya.audio ? <audio
                        controls
                        src={parkiya.audio}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio> : null}
                </Typography>
                <Share text={parkiya.title} link={`https://www.vakovakyam.com/परकीया/${parkiya.parkiyaId}`} />
                <div><br /><Divider /></div>

                {parkiya.content.map((line, index) => {
                    return (
                        <div key={index}>
                            <Typography className={classes.line} align={line.align} variant="body1" gutterBottom>
                                {line.text}
                            </Typography>
                            <Divider />
                        </div>
                    );
                })}
            </main>
        </div>
    );
}

export default Parkiya;