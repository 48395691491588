import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

export default function ContributorSlice() {
    return (
        <div className="col-lg-4">
            <Typography align="center"
                variant="h4"
                color="inherit">
                Contributor of the month
            </Typography>
            <img className="avatar" src="/images/chetana-pandey.jpeg" alt="avatar" />
            <Typography align="center"
                variant="h5"
                color="primary">
                Dr. Chetana Pandey
                    </Typography>
            <Typography align="center"
                variant="h6"
                color="inherit">
                A Hindi scholar, writer and teacher
            </Typography>
            <div className="text-center">
                <Link to="/contributor/dr-chetana-pandey" type="button" className="btn btn-outline-primary m-3">Show details</Link>
            </div>
        </div>
    );
}