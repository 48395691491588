import React from 'react';
import { useRecoilValue } from 'recoil';
import { videosState } from '../../data/content';
import { Link } from "react-router-dom";
import tagToPath from '../../utils/tagToPath';

function Latest() {
    const videos = useRecoilValue(videosState).slice(0, 5);
    return (
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                {videos.map((video, index) => {
                    const link = `/media/${tagToPath(video.hashtags[0])}/${video.videoId}`;
                    return (
                        <div key={video.videoId} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                            <Link className="d-block" to={link}>
                                <img className="d-block w-100" src={video.thumbnail} alt="First slide" />
                                <div className="carousel-caption d-none d-md-block">
                                    <p>{video.title}</p>
                                </div>
                            </Link>
                        </div>
                    )
                })
                }
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
}

export default Latest;