import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { mobileOpenState, mobileShowState } from '../data/content';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItemLink from './ListItemLink';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        background: '#000000',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    }
}));

export default function SideBar(props) {
    const { window } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useRecoilState(mobileOpenState);
    const [, setMobileShow] = useRecoilState(mobileShowState);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        setMobileOpen(false);
        setMobileShow(true);
        return () => {
            setMobileOpen(false);
            setMobileShow(false);
        }
    }, [setMobileOpen, setMobileShow])
    
    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {props.data.map(item => (
                    <ListItemLink key={item.title} selected={item.selected} to={item.href} primary={item.title} />
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp>
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor='left'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    );
}