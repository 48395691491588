import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { fsui, signInOptions } from '../utils/firebaseAuth'

function Login() {
    const history = useHistory();
    useEffect(() => {
        const uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: () => {
                    history.push("/home");
                    return false;
                }
            },
            signInFlow: 'popup',
            signInOptions: signInOptions,
            tosUrl: 'http://vakovakyam.herokuapp.com/',
            privacyPolicyUrl: 'http://vakovakyam.herokuapp.com/'
        }
        fsui.start('#firebaseui-auth-container', uiConfig);

    }, [history])
    return (
        <div className="container">
            <div className="row m-5">
                <div className="col">
                    <div id="firebaseui-auth-container"></div>
                </div>
            </div>
        </div>
    );
}

export default Login;