import React from 'react';
import { FacebookProvider } from 'react-facebook';
function Facebook({ children }) {
    return (
        <FacebookProvider appId="990985594706668">
            {children}
        </FacebookProvider>
    );
}

export default Facebook;