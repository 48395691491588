import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { aartiState } from '../data/content';
import { useParams } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import SideBar from './SideBar';
import Share from './Share';
import { useLocation } from "react-router-dom";
import Meta from './Meta';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function Aarti() {
    const { pathname } = useLocation();
    const classes = useStyles();
    const aartiList = useRecoilValue(aartiState);
    const { aartiId } = useParams();
    const aarti = aartiList.find(aarti => aarti.id === aartiId);
    const sideBarData = aartiList.map(aarti => ({ title: aarti.title, href: `/aarti/${aarti.id}`, selected: aarti.id === aartiId }))

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Meta
                title={aarti.title}
                description={aarti.content.slice(0, 50)}
                link={`https://www.vakovakyam.com/aarti/${aarti.id}`}
                image={aarti.image} />
            <SideBar data={sideBarData} />
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">Vakovakyam</Link>
                    <Typography color="textPrimary">स्तवनम्</Typography>
                </Breadcrumbs>
                <img className="avatar" src={aarti.image} alt="Aarti" />
                <Share text={aarti.title} link={`https://www.vakovakyam.com/aarti/${aarti.id}`} />
                <p className="shlok display-linebreak">
                    {aarti.content}
                </p>
            </main>
        </div>
    );
}

export default Aarti;