import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useRecoilValue } from 'recoil';
import { videosState } from '../../data/content';
import tagToPath from '../../utils/tagToPath';
import MediaCard from './MediaCard';

function MediaItem({ section }) {
    const videos = useRecoilValue(videosState)
        .filter(video => video.hashtags.map(hashtag => tagToPath(hashtag)).includes(section));
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {videos.map(video => {
                const reactLink = `/media/${section}/${video.videoId}`;
                return (
                    <MediaCard key={video.videoId} image={video.thumbnail} title={video.title} link={reactLink}></MediaCard>
                );
            })}
        </Carousel>
    );
}

export default MediaItem;
