import React from 'react';
import { videosState } from '../../data/content';
import { useRecoilValue } from 'recoil';
import { useParams } from "react-router-dom";
import tagToPath from '../../utils/tagToPath';
import MediaCard from './MediaCard';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function MediaSection() {
    const { section } = useParams();
    let videos = useRecoilValue(videosState);
    if (section === "latest") {
        videos = videos.slice(0, 5);
    } else {
        videos = videos.filter(video => video.hashtags.map(hashtag => tagToPath(hashtag)).includes(section));
    }

    return (
        <div className="container">
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">Vakovakyam</Link>
                <Link color="inherit" href="/media">media</Link>
                <Typography color="textPrimary">{section}</Typography>
            </Breadcrumbs>
            <div className="row m-5">
                {videos.map(video => {
                    const reactLink = `/media/${section}/${video.videoId}`;
                    return (
                        <div className="col-lg-4 mb-5">
                            <MediaCard key={video.videoId} image={video.thumbnail} title={video.title} link={reactLink}></MediaCard>
                        </div>
                    );
                })}

            </div>
        </div>
    );

}

export default MediaSection;