import React from 'react';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    gridList: {
        width: 500,
    },
}));

export default function GallerySlice() {
    const classes = useStyles();
    const images = [
        {
            thumbnail: 'images/vakovakyam/2020/1.jpg',
            cols: 2,
        },
        {
            thumbnail: 'images/vakovakyam/2020/2.jpg',
        },
        {
            thumbnail: 'images/vakovakyam/2020/3.jpg',
        },
        {
            thumbnail: 'images/vakovakyam/2020/4.jpg',
        },
        {
            thumbnail: 'images/vakovakyam/2020/5.jpg',
            cols: 2,
        },
        {
            thumbnail: 'images/vakovakyam/2020/7.jpg',
        }
    ];
    return (
        <>
            <Typography align="center"
                variant="h4"
                color="inherit">
                वाकोवाक्यम् सम्मान समारोह 2020
            </Typography>
            <Grid container justify="center">
                <GridList justify="center" cellHeight={160} className={classes.gridList} cols={4}>
                    {images.map((tile) => (
                        <GridListTile key={tile.thumbnail} cols={tile.cols || 1}>
                            <img src={tile.thumbnail} alt="Gallery" />
                        </GridListTile>
                    ))}
                </GridList>
            </Grid>
            <div className="text-center">
                <Link to="/gallery/वाकोवाक्यम्-सम्मान-समारोह-2020" type="button" className="btn btn-outline-primary m-3">Show more</Link>
            </div>
        </>
    );
}