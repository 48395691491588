import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
const firebaseConfig = {
    apiKey: "AIzaSyANj5MHzeGGv6oXatiIVgvIJjF7QXMIDv8",
    authDomain: "vakovakyam.firebaseapp.com",
    databaseURL: "https://vakovakyam.firebaseio.com",
    projectId: "vakovakyam",
    storageBucket: "vakovakyam.appspot.com",
    messagingSenderId: "885560568794",
    appId: "1:885560568794:web:87368eb98509a308766ee3",
    measurementId: "G-VDJS2P5PL1"
};
export const signInOptions = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID
]
export const fs = firebase.initializeApp(firebaseConfig);
const firebaseAuth = fs.auth();
export const fsui = new firebaseui.auth.AuthUI(firebaseAuth);
export default firebaseAuth;